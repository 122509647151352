import React from 'react';
import { Typo } from '@geberit/gdds';

// components
import { Headline, Formats, getFontWeight } from '../../ContentElements/Headline';

// styles
import styles from './interactive-hotspot.module.scss';

// utils
import { textFormat } from './formats';
import { CmsLink } from '../../Link/CmsLink';
import { useThemeName } from '../../../utils/hooks/use-theme';

interface ContentProps {
  title: string;
  subtitle: string;
  emphasize: string;
  description: string;
  link: {
    target: string;
    text: string;
    type: string;
    window: string;
  };
  textLeft: boolean;
  textRef: any;
  isMobile: boolean;
}

function Content({
  textRef,
  title,
  subtitle,
  emphasize,
  description,
  isMobile,
  link,
  textLeft,
}: Readonly<ContentProps>) {
  const { text, target } = link || {};
  const headlineOnly = !description && !text && !target ? 'headlineOnly' : '';
  const isTextLeft = textLeft && 'textLeft';
  const themeName = useThemeName();

  return (
    <div
      className={`${styles.textWrapper} ${styles[headlineOnly]} ${styles[isTextLeft]}`}
      ref={textRef}
    >
      {(title || subtitle) && (
        <Headline
          title={title}
          subtitle={subtitle}
          tag={Formats.h2}
          format={Formats.h1}
          titleFontWeight={getFontWeight(emphasize === 'title', themeName, Formats.h1)}
          subtitleFontWeight={getFontWeight(emphasize === 'subtitle', themeName, Formats.h1)}
          titlePreviewId="#st_title"
          subtitlePreviewId="#st_subtitle"
        />
      )}
      {/* description */}
      {!isMobile && description && (
        <Typo tag="p" size={textFormat} previewId="#st_description">
          {description}
        </Typo>
      )}
      {/* link */}
      {text && target && (
        <CmsLink
          link={link}
          standardFontSize={false}
          className={styles.link}
          alignByContent={link.showArrow ? 'left' : ''}
        />
      )}
      {isMobile && description && (
        <Typo tag="p" size={textFormat}>
          {description}
        </Typo>
      )}
    </div>
  );
}

export default Content;
