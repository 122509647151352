import React, { useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import isEmpty from 'lodash.isempty';
import { Button } from '@geberit/gdds';

// components
import { Headline, Formats, FontWeights } from '../../ContentElements/Headline';
import { Image } from '../../Image/Image';
import { InnerHtml } from '../../InnerHtml/inner-html';
import { CmsLink } from '../../Link/CmsLink';

// utils
import { classNameBuilder } from '../../../utils/classNameBuilder';
import { useKolo } from '../../../utils/hooks/use-kolo';

// styles
import styles from './interactive-hotspot.module.scss';
import { useIsDesktop, useIsMobile, useIsTablet } from 'components/App/SizeProvider';

interface OverlayProps {
  reset: () => void;
  activeHotspot: any;
  imageRef: any;
  textRef: any;
  imageAlignment: string;
  isOpen: boolean;
}

function Overlay({
  reset,
  activeHotspot,
  imageRef,
  textRef,
  imageAlignment,
  isOpen,
}: Readonly<OverlayProps>) {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const theme = useTheme();
  const { headline, subline, text, picture, pictureObject, pictureAlt, link } =
    activeHotspot?.description || {};
  const ref = useRef();
  const isKolo = useKolo();

  const reposition = () => {
    const overlayHeight = ref.current.offsetHeight;
    const imageHeight = imageRef.current.offsetHeight;
    const containerHeight = ref.current.parentElement.offsetHeight;
    const hotspotTop = parseFloat(activeHotspot.position.top.replace('%', ''));

    const overlayWidth = ref.current.offsetWidth;
    const imageWidth = imageRef.current.offsetWidth;
    const containerWidth = ref.current.parentElement.offsetWidth;
    const hotspotLeft = parseFloat(activeHotspot.position.left.replace('%', ''));
    const dotLeft = imageRef.current.offsetLeft + (imageWidth * hotspotLeft) / 100;

    // --- top alignment
    let overlayPosTop = (imageHeight * hotspotTop) / 100 - overlayHeight / 2;

    // if the text is above the image we need to add it to the top offset
    if (imageAlignment === 'center' || isMobile || isTablet) {
      overlayPosTop += textRef.current.offsetHeight;

      if (isDesktop) {
        if ((imageHeight * hotspotTop) / 100 - overlayHeight / 2 < 0) {
          overlayPosTop = textRef.current.offsetHeight;
        }
      }
    } else if (!isDesktop) {
      overlayPosTop += textRef.current.offsetHeight + 24;
    }

    // if the hotspot is at the end of the picture we try to align the overlay on the bottom
    if (overlayPosTop + overlayHeight > containerHeight) {
      overlayPosTop = Math.ceil(containerHeight - overlayHeight);
    }

    // if the overlay is too long we position it on the top
    if (overlayPosTop < 0) {
      overlayPosTop = 0;
    }

    ref.current.style.top = `${overlayPosTop}px`;

    // --- left alignment
    let imagePosition = imageAlignment;

    // if the picture uses the full width we need to check on which side we show the overlay
    if (imageAlignment === 'center') {
      if (hotspotLeft < 50) {
        imagePosition = 'left';
      } else {
        imagePosition = 'right';
      }
    }

    // we always want to position the overlay on the opposite side of the image
    // when a tablet device is used, the overlay will appear in the middle of the image
    let overlayPosLeft;

    if (isTablet) {
      overlayPosLeft = (imageWidth - overlayWidth) / 2;
      ref.current.style.left = `${overlayPosLeft}px`;
    }
    if (!isTablet) {
      if (imagePosition === 'right') {
        overlayPosLeft = dotLeft - overlayWidth - 48; // 3 rem margin
        ref.current.style.left = `${overlayPosLeft > 0 ? overlayPosLeft : 0}px`;
      } else {
        overlayPosLeft = dotLeft + 48; // 3 rem margin
        const maxLeftPos = Math.ceil(containerWidth - overlayWidth);
        ref.current.style.left = `${overlayPosLeft < maxLeftPos ? overlayPosLeft : maxLeftPos}px`;
      }
    }

    // small hack to prevent flickering of the overlay
    ref.current.style.visibility = 'visible';
  };

  useEffect(() => {
    if (activeHotspot && isOpen) {
      reposition();
      window.addEventListener('resize', reposition);
      return () => window.removeEventListener('resize', reposition);
    }
    return () => {};
  }, [activeHotspot, isOpen, isMobile, isTablet]);

  return (
    <div className={classNameBuilder(styles.overlay, !isOpen && styles.closed)} ref={ref}>
      <div className={styles.overlayText}>
        <Button
          isIcon
          symbol="Close"
          className={styles.closeIcon}
          onClick={reset}
          stylingType="icon"
          color={theme.colors.grey13}
        />
        <Headline
          title={headline}
          subtitle={subline}
          format={Formats.h3}
          titleFontWeight={isKolo ? FontWeights.bold : FontWeights.medium}
          subtitleFontWeight={isKolo ? FontWeights.bold : FontWeights.light}
          className={styles.overlayHeadline}
        />
        <InnerHtml as="div" content={text} className={styles.description} />
        {!isEmpty(link) && (
          <CmsLink
            link={link}
            stylingType="secondary"
            alignByContent="left"
            standardFontSize={false}
            className={styles.link}
          />
        )}
      </div>
      <div className={styles.overlayImage}>
        {!isEmpty(pictureObject) && (
          <Image
            src={picture}
            alt={pictureAlt}
            title={pictureAlt}
            data={pictureObject}
            aspectRatio={pictureObject.format}
            onLoad={reposition}
            lazy={false}
          />
        )}
      </div>
    </div>
  );
}

export default Overlay;
