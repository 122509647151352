import { useRef, useState } from 'react';
import { Container } from '@geberit/gdds';

// styles
import styles from './interactive-hotspot.module.scss';

// components
import Overlay from './overlay';
import ContentWrapper from './content-wrapper';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { useSectionId } from 'utils/hooks/use-section-id';

const backgroundColors = {
  white: styles.bgWhite,
  grey: styles.bgGrey,
};

export interface InteractiveHotspotProps {
  imageMap: {
    areas: Area[];
    image: string;
    imageAlt: string;
    imageObject: ImageObject;
  };
  imageAlignment: string;
  backgroundColor: 'white' | 'grey';
  colorScheme: string;
  contentIndex: number;
  titleInAnchor: boolean;
  title?: string;
  subtitle?: string;
  emphasize: string;
  description?: string;
  link?: Link;
  anchor?: string;
}

export function InteractiveHotspot({
  imageMap,
  imageMap: { imageObject },
  imageAlignment,
  backgroundColor,
  colorScheme,
  title,
  subtitle,
  emphasize,
  description,
  link,
  anchor,
  contentIndex,
  titleInAnchor,
}: Readonly<InteractiveHotspotProps>) {
  const sectionId = useSectionId(title ?? '', anchor ?? '', titleInAnchor, contentIndex);
  const [lastHotspot, setLastHotspot] = useState();
  const [hotspotOpen, setHotspotOpen] = useState(false);
  const resetHotspot = () => setHotspotOpen(false);
  const imageRef = useRef();
  const textRef = useRef();
  const imageFormat = imageObject.aspectRatio === '2_1' ? 'wide' : 'portrait';

  return (
    <div
      className={classNameBuilder(
        styles.wrapper,
        backgroundColors[backgroundColor],
        styles[imageAlignment],
      )}
      id={sectionId}
    >
      <Container
        className={imageFormat === 'wide' ? styles.horizontalImageScroll : ''}
        maxContentWidth="78rem"
      >
        <ContentWrapper
          imageAlignment={imageAlignment}
          title={title}
          subtitle={subtitle}
          link={link}
          emphasize={emphasize}
          textRef={textRef}
          imageRef={imageRef}
          description={description}
          imageMap={imageMap}
          colorScheme={colorScheme}
          lastHotspot={lastHotspot}
          setLastHotspot={setLastHotspot}
          hotspotOpen={hotspotOpen}
          setHotspotOpen={setHotspotOpen}
        >
          {/* overlay */}
          <Overlay
            reset={resetHotspot}
            imageRef={imageRef}
            textRef={textRef}
            activeHotspot={lastHotspot}
            imageAlignment={imageAlignment}
            isOpen={hotspotOpen}
          />
        </ContentWrapper>
      </Container>
    </div>
  );
}
