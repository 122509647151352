import { useContext } from 'react';
import key from 'weak-key';
import equals from 'lodash.isequal';
import { useTheme } from 'styled-components';

// styles
import styles from './hotspot.module.scss';

// types
import type { ActiveHotspot } from '../ProductSlider/types';
import type { HotspotsProps } from './types';

// utils
import { clickHotspot } from './trackingActions';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useTracking } from 'utils/hooks/useTracking';
import { decodingContent } from 'utils/decodingContent';
import { ProductSliderContext } from '../ProductSlider/context';

export default function Hotspots({
  areas,
  colorScheme,
  isWorldMap,
  activeHotspot,
  setActiveHotspot,
  lastHotspot,
  setLastHotspot,
  hotspotOpen,
  setHotspotOpen,
  setTooltip,
  onClick,
  direction,
  imageIndex = 0,
}: Readonly<HotspotsProps>) {
  const track = useTracking();
  const theme = useTheme();
  const { slidePercentage } = useContext(ProductSliderContext);

  const colors = {
    dark: theme.colors.black,
    black: theme.colors.black,
    light: theme.colors.white,
    white: theme.colors.white,
    active: theme.colors.primaryLight,
  };

  return (
    <svg className={styles.hotspots}>
      {areas.map((imageItem) => {
        const isActive = isWorldMap
          ? equals(imageItem, activeHotspot)
          : hotspotOpen && equals(imageItem.position, lastHotspot?.position);
        const svgLeft = parseFloat(imageItem.position.left.split('%')[0]);
        const isVisible =
          imageIndex === 0 || (slidePercentage !== undefined && slidePercentage < svgLeft);
        const overlayPosition = svgLeft <= 50 ? 'right' : 'left';

        return (
          <g
            key={key(imageItem)}
            className={classNameBuilder(
              !isVisible ? styles.isHidden : '',
              isActive ? styles.active : undefined,
              isActive && isWorldMap ? styles.hideCompletly : '',
            )}
            cx={imageItem.position.left}
            cy={imageItem.position.top}
            r={isActive ? 10 : 5}
            stroke={`${colors[colorScheme] ?? '#000000'}33`}
            strokeWidth={isActive ? 28 : 20}
            fill={colors[colorScheme] || theme.colors.black}
            onClick={(e) => {
              if (onClick) onClick(e, direction);
              if (isWorldMap) setActiveHotspot?.(imageItem as ActiveHotspot);
              else if (setActiveHotspot)
                setActiveHotspot({ ...imageItem, overlayPosition, imageIndex });
              if (setLastHotspot) setLastHotspot({ ...imageItem, overlayPosition, imageIndex });
              if (setHotspotOpen) setHotspotOpen(true);
              if (setTooltip) setTooltip();

              track.trackEvent(
                clickHotspot(decodingContent(imageItem.description?.headline ?? '')),
              );
            }}
            {...(imageItem.tooltip && setTooltip
              ? {
                  onMouseOver: () => setTooltip(imageItem),
                }
              : {})}
            {...(imageItem.tooltip && setTooltip ? { onMouseLeave: () => setTooltip() } : {})}
          >
            {/* corona */}
            <circle
              className={styles.corona}
              cx={imageItem.position.left}
              cy={imageItem.position.top}
              r={7}
              fill={`${colors[colorScheme] ?? '#000000'}33`}
              stroke={`${colors[colorScheme] ?? '#000000'}33`}
            />
            {/* inner */}
            <circle
              className={styles.dot}
              cx={imageItem.position.left}
              cy={imageItem.position.top}
              r={7}
              fill={colors[colorScheme] || theme.colors.black}
            />
          </g>
        );
      })}
    </svg>
  );
}
