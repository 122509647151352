import slugify from 'slugify';
import key from 'weak-key';

/**
 * Generate section div id
 *
 * @param {String} title
 * @param {String} anchor
 * @param {String} tocData
 * @param {Number} contentIndex
 */

export function getSectionId(
  title: string,
  anchor: string,
  tocData: readonly { id: string }[],
  contentIndex: number,
) {
  const id: string =
    title || anchor
      ? `section-${generateSectionIdSlug(anchor || title)}`
      : tocData?.[contentIndex]?.id || `section-index-${contentIndex}`;

  return id;
}

export const generateSectionIdSlug = (phrase: string, prefix?: string) => {
  if (!phrase || phrase.length === 0) return key({ phrase });

  const slug = slugify(`${phrase}`, {
    remove: /[*+~.,;#()'"!:`/–@?°]/g,
    lower: true,
  });

  return prefix ? `${prefix}-${slug}` : slug;
};
