import { trackTemplate } from '../../../utils/tracking';

/**
 * Insights filter click
 * @param string text
 */
export function clickHotspot(title) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Clicks',
      action: 'HotSpot',
      label: title,
      value: '0',
    },
  });
}
